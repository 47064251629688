/* Typography */

@mixin atma {
    font-family: 'Atma', cursive;
}


@mixin header($weight, $size) {
    font-size: #{($size/$remFontSize)}em;
    font-weight: $weight;

    @media screen and (max-width: $md) {
        font-size: #{($size/$remFontSize)*0.9}em;
    }

    @media screen and (max-width: $sm) {
        font-size: #{($size/$remFontSize)*0.8}em;
    }

    @media screen and (max-width: $xs) {
        font-size: #{($size/$remFontSize)*0.6}em;
    }
}

@mixin responsiveFont($lgFont, $mdFont, $smFont, $xsFont) {
    font-size: $lgFont;

    @media screen and (max-width: $md) {
        font-size: $mdFont;
    }

    @media screen and (max-width: $sm) {
        font-size: $smFont;
    }

    @media screen and (max-width: $xs) {
        font-size: $xsFont;
    }
}

/* Responsive */

@mixin mqlg {
    @media only screen and (max-width: $lg) {
        @content;
    }
}

@mixin mqmd {
    @media only screen and (max-width: $md) {
        @content;
    }
}

@mixin mqsm {
    @media only screen and (max-width: $sm) {
        @content;
    }
}

@mixin mqxs {
    @media only screen and (max-width: $xs) {
        @content;
    }
}


/* Animation and effects */
@mixin rotate($angle) {
    -webkit-transform: rotate($angle);
    -moz-transform: rotate($angle);
    -ms-transform: rotate($angle);
    transform: rotate($angle);
}

@mixin transition($property, $duration, $function) {
    -webkit-transition: $property $duration $function;
    -moz-transition: $property $duration $function;
    -ms-transition: $property $duration $function;
    -o-transition: $property $duration $function;
    transition: $property $duration $function;
}

@mixin standardtransition() {
    -webkit-transition: all  0.2s ease-in-out;
    -moz-transition: all  0.2s ease-in-out;
    -ms-transition: all  0.2s ease-in-out;
    -o-transition: all  0.2s ease-in-out;
    transition: all  0.2s ease-in-out;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str, $fill-mode) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
    -webkit-animation-fill-mode: $fill-mode;
    -moz-animation-fill-mode: $fill-mode;
    -ms-animation-fill-mode: $fill-mode;
    -o-animation-fill-mode: $fill-mode;
    animation-fill-mode: $fill-mode;
}

/* Before/After */

@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}
