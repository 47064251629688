html,
body {
    @include atma;
    font-weight: 400;
    font-size: $remFontSize;
    color: $textColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include atma;
    font-weight: 400;
    font-size: 18px;

    a {
        color: inherit;
        text-decoration: underline;
    }
}

h1 {
    @include header(600, 60px);
    text-transform: uppercase;
}

h2 {
    @include header(600, 60px);
    text-transform: uppercase;
    margin: 36px 0;

    &.birds {
        position: relative;

        &:after {
            @include pseudo;
            top: -200%;
            right: -50%;
            width: 559px;
            height: 171px;
            background-image: url('../images/birds.png');

            @media (max-width: $sm) {
                content: none;
            }
        }
    }
}

h3 {
    font-weight: 600;
    margin-bottom: 24px;
    text-transform: uppercase;
    @include responsiveFont(30px, 24px, 24px, 21px);
    &.yellow {
        color: $navyellow;
    }
}

h4 {
    font-size: 24px;
}

h6 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 18px;
}

p {
    font-size: 18px;
    line-height: 1.65;
    font-weight: 400;
    margin-bottom: 24px;

    strong {
        font-weight: 500;
    }

    a {
        color: $textColorInvert;
        text-decoration: underline;
    }

    em {
        color: #61c0d8;
        font-style: normal;
    }
}

blockquote {
    font-size: 18px;
    border-left: 4px solid rgba(238,238,238,.5);

    footer {
        font-size: 90%;
    }
}
