.flex {
    display: flex;
}

.flex-reverse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.flex-column-reverse {
    @media (max-width: $sm) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }
}

.flex-columns-3 {
    display: flex;
    flex-wrap: wrap;
}

.flex-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (max-width: $sm) {
        justify-content: center;
        max-width: 280px;
        margin: auto;
    }
}

.flex-reversed-text {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;

    @media (max-width: $sm) {
        justify-content: center;
        max-width: 280px;
        margin: auto;
    }
}

.flex-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-end {
    align-self: flex-end;
    width: 100%;
}

.padding-right-0 {
    padding-right: 0;
}

.well {
    border-radius: 10px;
    background-color: $white;
    border: none;
    box-shadow: none;
    padding: 19px 30px;
}

.list-inline {
    margin-left: 0;
    margin-right: 0;
}

.nav-tabs {
    border-bottom: none;
}

.divider {
    &:before {
        content: '';
        width: 1px;
        display: block;
        height: 100%;
        background-color: $white;
        margin: auto;
    }
}

.margin-null {
    margin-bottom: 0 !important;
}

.text-center-xs {
    @media (max-width: $sm) {
        text-align: center;
    }
}
