.calendar {
    max-width: 500px;
    background-color: $white;
    color: $black;
    padding: 40px 10px;
    border-radius: 20px;
    font-weight: 500;
    @include responsiveFont(31px, 26px, 24px, 20px);

    &.small {
        @include responsiveFont(21px, 21px, 20px, 18px);

        .week span {
            min-width: 30px;
        }
        .event-wrapper{
            padding: 5px 8px;
        }
    }
}

.month {
    text-transform: uppercase;
}

.days-of-week {
    display: flex;
    justify-content: space-around;
    padding: 15px 0 2px;
}

.week {
    @extend .days-of-week;
    padding: 0;

    span {
        display: inline-block;
        min-width: 43px;

        &.event {
            color: $uiElement;

            &.active {
                color: $white;
                background-color: $uiElement;
                border-radius: 50%;
            }
        }
    }
}

.days {
    display: flex;
}

.previuos-month, .next-month {
    padding: 0 30px;
}

.event-wrapper {
    padding: 10px 25px;

    h3 {
        text-transform: none;
        margin-bottom: 5px;
    }
}
