.goods-wrapper {
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -20px;

    @media (max-width: $lg) {
        margin: 0 -10px;
    }

    .column-wrapper {
    }

    section {
        padding: 30px 0;
        width: 33%;
        padding: 20px;
        
        @media (max-width: $lg) {
            padding: 10px;
        }

        @media (max-width: $md) {
            width: 50%;
        }

        @media (max-width: $sm) {
            width: 100%;
            padding: 10px;
        }

        iframe {
            max-width: 100%;
        }

        .background-wrapper {
            background-color: $skyblue;
            padding: 0;
            height: 100%;
        }
    }

    .caption {
        padding: 5px 15px;

        .date-added {
            color: $blue;
            font-size: 66%;
            margin-bottom: 5px;
        }
    }
}
