.btn {
    @include transition(all, .2s, ease-in);
    border: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 18px;
    padding: .4em 2.2em;

    &:hover, &:focus, &:active:focus {
        -webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.18);
        -moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.18);
        box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.18);
        outline: none;
    }
}

.btn-wide {
    padding: .4em 2.6em;
}

.btn-sm {
    padding: .3em 0.5em;
}

.btn-lg {
    font-size: $remFontSize;
    padding: .5em 2em;
}

.btn-xl {
    padding: .8em 3em;
}

.btn-default {
    color: $black;
}

.btn-warning {
    color: #000000;
    background-color: $button;
}

.btn-details {
    background-color: $uiElement;
    color: $white;
}

.btn-link {
    color: $white;
    border-bottom: 1px solid $white;
    border-radius: 0;
    margin: .5em 2em;
    padding: 0;

    &:hover {
        text-decoration: none;
        color: $black;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
}

.close {
    @include standardtransition;
    opacity: 1;
    top: 40px;
    right: 75px;
    position: absolute;
    z-index: 1;
}

.play {
    cursor: pointer;
}

.btn-fight {
    width: 190px;
}

.btn-more {
    float: right;
    height: 30px;
    font-weight: 600;
    border: none;
    background: transparent;

    &.collapsed:before {
        content: "more";
    }

    &:before {
        content: "less";
    }
}

@media screen and (min-width: 768px) {
    .btn-more {
        display: none;
    }
}
