.carousel-caption {
    position: static;
    text-shadow: none;
    padding-top: 5px;

    p {
        margin-bottom: 0;
    }

    h3 {
        text-transform: none;
    }
}

.carousel-indicators {
    bottom: 100px;

    @media (min-width: 768px) {
        bottom: 140px;
    }

    li {
        width: 12px;
        height: 12px;
        margin: 0 3px;
    }

    .active {
        margin: 0 4px;
    }
}
