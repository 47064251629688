label {
    font-weight: 300;
    cursor: pointer;
}

.form-control {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 18px;
    height: 38px;
    border-radius: 3px;

    &.bordered {
        border: 1px solid $black;

        &:focus {
            border-color: $uiElement;
            box-shadow: none;
        }
    }
}

.form-group {
    margin-bottom: 24px;

    @media (max-width: $xs) {
        margin-bottom: 15px;
    }

    .btn {
        margin-top: 10px;
    }
}

.form-white {
    background-color: $white;
    color: $black;
    padding: 15px;
    border-radius: 3px;

    @media (min-width: 992px) {
        padding: 25px 35px;
    }
}

.subscribe-form-wrapper {
    margin-bottom: 30px;

    form {
        position: relative;

        &:before {
            content: '';
            background-color: $white;
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            left: -22%;
        }
    }
}

.contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 36px;

    .name,
    .email {
        width: 48%;
    }

    .subject {
        width: 100%;
    }

    .message {
        width: 100%;
    }

    .submit {
        margin-top: 24px;

        @media (max-width: $sm) {
            display: block;
            width: 100%;
            margin-top: 10px;
        }
    }
}

textarea {
    resize: none;
}

.checkbox-group {
    padding-top: 12px;
}

[type="checkbox"]:not(:checked) + label {
    .checkbox-icon {
        background-image: none;
    }
}

.get-ticket-form {
    .form-group {
        margin-bottom: 32px;
    }

    .checkbox-group {
        margin-bottom: 15px;
    }
}
