.information {

    h3 {
        text-transform: uppercase;
        margin-bottom: 48px;

        @media (max-width: $sm) {
            margin-bottom: 15px;
        }
    }

    strong {
        color: $textTitle;
        font-weight: 600;
        margin: 0 0 24px 0;
        display: inline-block;
    }
}

address {
    font-size: 18px;
    margin-bottom: 42px;

    @media (max-width: $sm) {
        color: $textTitle;
        margin-bottom: 10px;
    }
}

.dl-horizontal {
    font-size: 18px;
    overflow: hidden;

    dt {
        width: auto;
        font-weight: 300;

        @media (max-width: $sm) {
            display: inline-block;
        }
    }

    dd {
        float: left;
        margin-left: 12px;
        font-weight: 400;

        @media (max-width: $sm) {
            color: $white;
            float: none;
            display: inline-block;
        }
    }
}

strong.contact-subheading {
    @media (max-width: $sm) {
        color: $white;
        max-width: 270px;
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}
