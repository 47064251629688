.gallery-wrapper {
    flex-wrap: wrap;
    margin: 0 -20px;

    section {
        padding: 0 30px 20px;
        width: 25%;

        @media (max-width: $lg) {
            width: 33%;
        }

        @media (max-width: $sm) {
            width: 50%;
        }

        @media (max-width: $xs) {
            width: 100%;
        }

        .picture {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: relative;

            &:hover, &:focus, &:active:focus {
                .view-bg {
                    opacity: 1;
                }
            }
        }

        .img-wrapper {
            width: 100%;
        }

        img {
            width: 100%;
        }
    }
}

.view-bg {
    @include standardtransition;
    content: '';
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: $white;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .view-text {
        margin-bottom: 0;
    }

    .left-line, .right-line {
        display: inline-block;
        background-color: $white;
        height: 1px;
        width: 33px;
        vertical-align: middle;
    }
}

.picture-author {
    font-weight: 300;
    margin-bottom: 3px;
    margin-top: 8px;

    a {
        font-weight: 600;
        color: inherit;
        text-decoration: none;
        position: relative;

        &:hover, &:focus, &:active:focus {
            &:after {
                background-color: transparent;
            }
        }

        &:after {
            @include standardtransition;
            content: '';
            display: inline-block;
            position: absolute;
            height: 1px;
            width: 100%;
            bottom: 3px;
            left: 0;
            background-color: $white;
        }
    }
}

.location {
    color: $textblue;
}

.btn-buy {
    position: absolute;
    top: 0;
    right: 30px;
    color: $white;
    padding: 6px 19px 10px;
    font-weight: 300;
    background-color: rgba(0,0,0,0.5);
    border-radius: 2px;
    z-index: 1;

    &:hover, &:focus, &:active:focus {
        color: $uiElement;
        background-color: rgba(0,0,0,0.7);

        &:after {
            background-color: $uiElement;
        }
    }

    &:after {
        @include standardtransition;
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: $white;
        margin-top: -2px;
    }
}

.gallery-window {
    background-color: $black;

    .arrow-prev {
        @include standardtransition;
        position: absolute;
        top: 0;
        bottom: 10%;
        display: flex;
        width: 140px;
        align-items: center;
        justify-content: flex-end;

        &:hover {
            opacity: 0.3;
        }
    }

    .arrow-next {
        @extend .arrow-prev;
        right: 0;
        justify-content: flex-start;
    }

    .picture-wrapper {
        height: 100%;
    }

    .images-track {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
        }
    }

    .picture-author {
        margin-top: 10px;
    }

    .location {
        color: $white;
    }

    .btn-link {
        margin: 10px 0;
        font-weight: 300;

        &:hover, &:focus, &:active:focus {
            color: $white;
        }
    }
}
