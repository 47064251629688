$eventbubble: 425px;
$eventbubbleLaptop: 300px;
$eventbubbleMobile: 200px;

@mixin bubblemedia($point) {
    @if $point == desktop {
        @media (max-width: 1500px) {
            @content;
        }
    }
    @else if $point == mobileTablet {
        @media (max-width: 767px) {
            @content;
        }
    }
}

.events {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    section {
        padding: 10px;
        position: relative;
        max-width: $eventbubble;
        min-width: 125px;

        @include bubblemedia(desktop) {
            max-width: $eventbubbleLaptop;
        }

        @include bubblemedia(mobileTablet) {
            max-width: $eventbubbleMobile;
            margin: 5px 0;
        }

        &:nth-child(5n+2) {
            max-width: $eventbubble - 105px;

            @include bubblemedia(desktop) {
                max-width: $eventbubbleLaptop - 105px;
            }
        }

        &:nth-child(5n+3) {
            max-width: $eventbubble - 235px;
            align-self: flex-start;
            margin-top: 25px;

            @include bubblemedia(desktop) {
                max-width: $eventbubbleLaptop - 235px;
            }

            .event-date {
                width: 65px;
                height: 65px;
                font-size: 21px;
            }
        }

        &:nth-child(5n+4) {
            max-width: $eventbubble - 105px;

            @include bubblemedia(desktop) {
                max-width: $eventbubbleLaptop - 105px;
            }
        }

        &:nth-child(5n+5) {
            max-width: $eventbubble - 275px;
            align-self: flex-start;

            .event-date {
                width: 50px;
                height: 50px;
                font-size: 16px;
            }
        }

        img {
            max-width: 100%;
            border-radius: 50%;
        }
    }
}

.event-date {
    position: absolute;
    top: 0;
    right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 87px;
    width: 87px;
    background-color: $uiElement;
    color: $white;
    font-size: 30px;
    border-radius: 50%;

    @include bubblemedia(mobileTablet) {
        height: 60px;
        width: 60px;
        font-size: 24px;
    }
}

.bubble {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(../images/bubble-event.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.event-details {
    margin: 20px 0 42px;
    h3 {
        display: inline-block;
        margin-right: 60px;
    }
}

.participants {
    background-color: $white;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 60px;

    p {
        margin: 0;
    }
}

.events-soon {
    display: flex;
    justify-content: space-between;
    section {
        position: relative;
        padding: 10px;
        max-width: 240px;

        img {
            max-width: 100%;
            border-radius: 50%;
        }
    }
}
