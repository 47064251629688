section {
    position: relative;
    padding: 60px 0;
    overflow: hidden;

    header {
        margin-bottom: 60px;
    }

    .subsection {
        margin-bottom: 96px;
    }

    .flex {
        display: flex;

        @include mqxs {
            flex-wrap: wrap;
        }
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
            @include animation('bounce 48s linear infinite', both);
        }
    }

    .description {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
            margin: 0 0 36px 0;

            &[style] { //forcing text elements edited in admin panel be centered on mobile
                @media (max-width: 767px) {
                    text-align: center !important;
                }
            }
        }
    }

    .clouds,
    .grass {
        position: absolute;
        width: 100%;
    }

    .clouds {
        top: 0;
        height: 561px;
        background-position: center top;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: .3;
        background-image: url('../images/clouds-white.png');
        background-size: auto auto;
        background-position: center -200px;
    }

    .grass {
        bottom: 0;
        height: 195px;
        background-position: top center;
    }

    &.landing {
        position: relative;
        background-color: $blue;
        color: $textColorInvert;
        padding-top: 0;

        h1 {
            @media (max-width: $xs) {
                margin-top: 50px;
            }

            br {
                @media (max-width: $xs) {
                    display: none;
                }
            }
        }

        .clouds {
            width: 2560px;
            left: -15%;
            @include animation('bounce-X 48s linear infinite', both);
            background-image: url('../images/landing-bg.png');
            background-position: center -175px;
            background-size: auto auto;
            opacity: 1;
            min-height: 904px;
        }

        .sky {
            position: relative;
            height: calc(100vh - 60px);
            display: flex;
            align-items: center;
            z-index: 24;
        }

        .grass {
            background-image: url('../images/grass-green-landing.png');
            background-repeat: no-repeat;
            z-index: 22;
        }

        /*.tree {
            background-image: url('../images/tree-main.png');
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 21;
        } */
        .tree-nav {
            position: absolute;
            bottom: -10px;
            left: 0;  
            
            .treeInner {
                position: relative;
            
                 img {
                    width: 100%;
                }
                 
                 .linkWrap {
                     position: absolute;
                     top: 0;
                     left: 0;
                     width: 100%;
                     height: 100%;
                     
                    
                     a {
                         position: absolute;
                         display: inline-block;
                         z-index: 24;
                     }
                      
                     .enroll {
                         top: 24.9%;
                         left: 52.4%;
                         width: 5.6%;
                         height: 8.1%;
                         
                     }

                     .aboutFight{
                         top: 3%;
                         right: 36.7%;
                         width: 5.6%;
                         height: 8.1%;
                     }

                     .dream-link {
                         top: 12.5%;
                         right: 24.2%;
                         width: 5.6%;
                         height: 8.1%;
                     }

                     .fightFree {
                         top: 25%;
                         right: 17.9%;
                         width: 5.6%;
                         height: 8.1%;
                     }

                     .fightPro {
                         top: 44%;
                        right: 21.1%;
                        width: 5.6%;
                        height: 8.1%;
                     }

                     .social-link {
                         top: 43.7%;
                         right: 47.8%;
                         width: 3%;
                         height: 5.5%;
                     }
                     
                     .involved {
                         top: 45%;
                         right: 41.2%;
                         width: 2.7%;
                         height: 5%;
                     }

                     .aboutUs {
                         top: 24.3%;
                         right: 37.3%;
                         width: 2.7%;
                         height: 5%;
                     }

                     .event-link {
                         top: 18.3%;
                         right: 32.4%;
                         width: 2.7%;
                         height: 5%;
                     }

                     .blog-link {
                         top: 37.5%;
                         right: 36.8%;
                         width: 2.5%;
                         height: 5%;
                     }

                     .thank-link {
                         top: 53.5%;
                         right: 37.5%;
                         width: 2.7%;
                         height: 5%;
                     }

                     .donate-link {
                         top: 39.3%;
                         right: 30.7%;
                         width: 2.7%;
                         height: 5%;
                     }

                     .corner-link {
                         top: 51.7%;
                         right: 30%;
                         width: 2.7%;
                         height: 5%;
                     }

                     .sponsors-link {
                         top: 26%;
                         right: 28.7%;
                         width: 2.7%;
                         height: 5%;
                     }

                 } 
            }
        }
    }

    &.landing-bg {
        min-height: 100vh;

        .clouds-mobile {
            display: none;

            @media (max-width: $sm) {
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-image: url('../images/clouds-birds-mobile.png');
                background-repeat: no-repeat;
                background-position: top center;
            }
        }
    }

    &.about {
        background: $green;
    }

    &.lime {
        background-color: $lime;
        color: $textColorInvert;

        .grass {
            background-image: url('../images/grass-green.png');
        }

        .image {
            align-items: center;
        }
    }

    &.pink {
        background-color: $pink;

        .grass {
            background-image: url('../images/grass-pink.png');
        }
    }

    &.darkblue {
        background-color: $darkblue;

        .clouds {
            background-image: url('../images/clouds-blue.png');
            opacity: 1;
            min-height: 904px;
        }

        .grass {
            background-image: url('../images/grass-green-landing.png');
        }
    }

    &.orange {
        background-color: $orange;
        color: $textColorInvert;

        .grass {
            background-image: url('../images/grass-orange.png');
        }
    }

    &.green {
        background-color: $green;
        color: $textColor;

        .grass {
            background-image: url('../images/grass-green.png');
        }

        .image {
            align-items: center;
        }
    }

    &.brown {
        background-color: $brown;

        .grass {
            background-image: url('../images/grass-brown.png');
        }
    }

    &.dark {
        background-color: $dark;
    }
}

.about {
    .row {
        @media (max-width: 992px) {
            display: block;
        }
    }
}

.about-description {
    margin-bottom: 35px;
    border-bottom: 1px solid $textColorInvert;

    .collapse-button-wrap {
        text-align: right;

        .btn-more {
            float: none;
        }
    }
}

.doctor-corner {
    .subsection {
        margin-top: 33px;
    }
}

article {
    &[class*="col-sm"] {
        @media (min-width: $sm) {
            padding-left: 67px;
        }
    }

    p {
        margin-bottom: 29px;
    }
}

.avatar-wrapper {
    margin-top: 25px;
    margin-bottom: 20px;

    img {
        max-width: 100%;
    }
}

.decreased-font {
    h1 {
        @include header(600, 48px);
        font-weight: 500;
        text-transform: none;
        letter-spacing: 1.2px;
        margin-bottom: 10px;
    }

    h2 {
        @extend h1;
    }

    p {
        line-height: 1.4;
    }

    article {
        p {
            line-height: 1.7;
        }

        h1 {
            @include header(600, 30px);
            font-weight: 600;
            text-transform: none;
            letter-spacing: 0;
            margin-bottom: 15px;
        }
    }
}

@media screen and (min-width: 768px) {
    .about-description {
        border-bottom: none;

        #more-about {
            display: block;
        }
    }
}
