.directors-list {
    > .tab-content > li, > li {
        padding: 10px 15px 6px;

        &:first-child {
            padding-left: 0;
        }

        &.active {
            a, a:focus, a:hover {
                border: none;
                background: none;

                .view-bg {
                    opacity: 0;
                }

                &:after {
                    border-color: $white;
                }

                img {
                    border: 1px solid #fff;
                    border-radius: 50%;
                }
            }
        }

        @media (max-width: $sm) {
            float: none;
        }

        a {
            padding: 0;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            /*align-items: center;*/
            position: relative;
            margin: auto;
            margin-bottom: 20px;
            border: none;
            background: none;

            .view-bg {
                border-radius: 50%;
            }

            &:after {
                @include standardtransition;
                content: '';
                position: absolute;
                display: block;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 50%;
                border: 9px solid transparent;
            }

            &:hover {
                .view-bg {
                    opacity: 1;
                }
            }

            &:focus, &:active:focus {
                border: none;

                &:after {
                    border-color: $white;
                }
            }

            img {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }
}

.director-name {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 500;
}

.director-description-list {
    font-weight: 500;
    border-bottom: 1px solid $black;
    margin-bottom: 21px;

    > li {
        padding: 6px 15px 19px;

        &:first-child {
            padding-left: 0;
        }

        &.active {
            a {
                color: $black;
                text-decoration: none;
            }
        }

        a {
            color: inherit;

            &:hover, &:focus, &:active:focus {
                color: $black;
                text-decoration: none;
            }
        }
    }
}

.directors-content {
    color: $grey;

    > .tab-pane {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: -30px;
            left: 85px;
            width: 0;
            height: 0;
            border: 15px solid transparent;
            border-bottom-color: $white;
        }

        &:nth-child(2) {
            &:before {
                left: 315px;
            }
        }

        &:nth-child(3) {
            &:before {
                left: 545px;
            }
        }
    }
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
}

/*-----------Mobile list-------------*/
.directors-list {
    .tab-content li {
        padding: 0;
        margin-top: 20px;

        &:first-child {
            margin-top: 0;
        }
    }

    img {
        width: 100%;
    }

    .director-title {
        padding: 10px 5px 5px 5px;
        background-color: $white;

        p {
            margin-bottom: 0;
            text-align: left;

            &:nth-child(2) {
                position: relative;
            }
        }

        .btn-more {
            position: absolute;
            bottom: -2px;
            right: 0;
        }
    }

    .director-description {
        padding: 0 5px 5px 5px;
        background-color: $white;

        .pictures img {
            float: left;
            width: 32%;
            margin-bottom: 5px;

            &:nth-child(3n-1) {
                margin-left: 2%;
                margin-right: 2%;
            }
        }

        .press img {
            float: left;
            width: 49%;
            margin-bottom: 5px;


            &:nth-child(odd) {
                margin-right: 2%;
            }
        }
    }

    .director-description-list {
        border: 0;

        li {
            width: 33.33%;
            padding: 15px 0px;
            border: 1px solid $textColorInvert;
            font-size: 18px;
            font-weight: 600;
            text-align: center;

            a {
                display: block;
            }

            &.active {
                background-color: $textColorInvert;

                a {
                    color: $white;
                }
            }
        }

        li:nth-child(2) {
            border-left: none;
            border-right: none;
            margin-left: -4px;
            margin-right: -4px;
        }
    }

}

#lime .btn-warning {
    margin-top: 30px;
}
