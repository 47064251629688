
﻿.blog {
    padding-top: 25px;
    background-color: $skyblue;

    header {
        margin-bottom: 60px;
        background-color: transparent;
        -webkit-box-shadow: 5px 5px 25px 0px rgba(0,0,0,0.32);
        -moz-box-shadow: 5px 5px 25px 0px rgba(0,0,0,0.32);
        box-shadow: 5px 5px 25px 0px rgba(0,0,0,0.32);

        .description {
            display: inline-block;
            position: relative;
            width: 100%;
            padding: 35px; 
            background-color: $darkblue;

            &:after {
                content: '';
                position: absolute;
                bottom: -16px;
                left: 50%;
                margin-left: -15px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 17px 15px 0 15px;
                border-color: $darkblue transparent transparent transparent;
            }

            & > div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }

            h1 {
                margin-top: 0;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 400;
            }

            h2 {
                margin-top: 0;
                text-transform: lowercase;
                text-transform: capitalize;
                font-size: 24px;
            }

            p {
                margin-bottom: 0;
                color: $navblue;
            }
        }

        img {
            width: 100%;
        }
    }

    .about {
        p {
            margin-bottom: 40px;
        }

        .picture {
            width: 100%;

            img {
                width: 100%;
                margin-bottom: 5px;
            }

            p {
                margin-bottom: 20px;
                color: $navblue;
            }
        }
    }

     .carousel {
         div {
             width: 100%;
             padding: 0 15px;

             img {
                 width: 100%;
                 margin-bottom: 5px;
             }
         }
     }

     .social {
         margin-bottom: 50px;
         text-align: center;
     }

     .relatedPosts {
         padding: 40px 0 100px 0;
         background-image: url('../images/grass-green-half.png');
         background-position: bottom;
         background-repeat: no-repeat;

         & > div {
             border-top: 1px solid $white;
         }

         h3 {
             text-transform: lowercase;
             font-weight: 500;
             
             span {
                 text-transform: uppercase;
             }
         }

         .post {
             width: 100%;
             margin-bottom: 60px;

             img {
                 width: 100%;
                 margin-bottom: 5px;
             }

             div p {
                 margin-bottom: 0;

                 &:first-child {
                     text-transform: uppercase;
                 }

                 &:nth-child(2) {
                     color: $navblue;
                 }
             }

             h2 {
                 margin-top: 10px;
                 font-size: 24px;
                 font-weight: 500;
                 text-transform: capitalize;
             }
         }
     }
}

@media screen and (min-width: 1200px) {
    .blog {
        background-image: url('../images/clouds-about.png');
        background-position: top;
        background-repeat: no-repeat;

        header {
            display: flex;

            .description {
                &:after {
                    left: 100%;
                    top: 20%;
                    margin-left: 0;
                    border-width: 15px 0 15px 17px;
                    border-color: transparent transparent transparent $darkblue;
                }
            }
        }

        .about {
            .inner-wrap div {
                display: inline-block;
                
                &:first-child {
                    max-width: 60%;
                }
            }

            .picture {
                float: right;
                max-width: 37%;
                margin-left: 3%;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .carousel {
            width: 400%;
            padding: 0;

            div {
                display: inline-block;
                float: left;
                width: auto;
                margin-bottom: 40px;
            }

        }

        .relatedPosts {
            h3 {
                font-size: 38px;
            }

            .post {
                float: left;
                width: 32%;

                &:nth-child(2) {
                    margin-left: 2%;
                    margin-right: 2%;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                }

                h2 {
                    font-size: 30px;
                }
            }
        }
    }
}

.blog-wrapper {
    header {
        margin-bottom: 10px;
    }

    &.decreased-font {
        h2 {
            margin-top: 10px;
            margin-bottom: 24px;
        }

        h3 {
            margin-top: 10px;
            margin-bottom: 15px;

            a {
                text-decoration: none;

                &:hover, &:focus {
                    color: $orange;
                }
            }
        }
    }

    .img-wrapper {
        overflow: hidden;
        margin-bottom: 5px;

        img {
            max-width: 100%;

            @media (min-width: $sm) {
                height: 180px;
            }

            @media (min-width: $lg) {
                height: 272px;
                max-width: none;
            }
        }
    }
}

.blog {
    margin-left: -20px;
    margin-right: -20px;

    section {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
        max-width: 500px;

        @media (max-width: $sm) {
            margin-left: auto;
            margin-right: auto;
        }

        p {
            margin-bottom: 0;
        }

        h3 {
            text-transform: none;
        }
    }
}

