.modal-dialog {
    @media (min-width: $sm) {
        width: 524px;
        margin: auto;
    }
}

.modal-content {
    padding: 24px;
}

.modal-header {
    border: none;
    padding: 10px 15px;
}

.modal-footer {
    border: none;
    text-align: center;
    padding: 5px 15px;
}

.fade.in {
    &.modal-flex {
        display: flex !important;
    }
}
