/* General colors */

$textColor: #ffffff;
$textColorInvert: #764c29;
$textTitle: #a69c93;

$white: #ffffff;

/* UI colors */

$body: #ffffff;
$uiElement: #f8be41;
$footer: #212121;

$button: #ffcc00;


/* Navigation */

$navblue: #2cb0d0;
$navlime: #abcc2e;
$navpink: #ca367f;
$navyellow: #ffcc00;
$navbrown: #ae7541;

/* Sections */
$grey: #575757;
$blue: #8cdef2;
$textblue: #8fdbee;
$lime: #cae660;
$green: #3ca207;
$pink: #e95aa1;
$orange: #ffaf00;
$brown: #d09157;
$darkblue: #006d88;
$skyblue: #338aa0;
$dark: #53351d;
$black: #000000;

.black {
    color: $black;
}
.lightblue{
    color: $textblue;
}
