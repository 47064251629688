.icon {
    display: inline-block;
    width: min-content;
    height: 25px;
    width: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon-close {
    background-image: url(../images/icons/close-icon-large.png);
}

.icon-sm {
    height: 18px;
    width: 15px;
}

.icon-lg {
    height: 100px;
    width: 55px;
}

.pointer-left {
    background-image: url(../images/icons/pointer.png);
}

.pointer-right {
    background-image: url(../images/icons/pointer.png);
    @include rotate(180deg);
}

.pointer-gallery-left {
    background-image: url(../images/icons/arrow-gallery-large.png);
}

.pointer-gallery-right {
    background-image: url(../images/icons/arrow-gallery-large.png);
    @include rotate(180deg);
}

.checkbox-icon {
    display: inline-block;
    min-width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-top: 4px;
    background-image: url(../images/icons/check-icon.png);
    background-size: 17px;
    border: 1px solid $black;
    border-radius: 5px;
}

.arrow-left {
    display: inline-block;
    background-image: url(../images/icons/pointer-slider.svg);
    background-repeat: no-repeat;
    width: 30px;
    height: 25px;
}

.arrow-right {
    @extend .arrow-left;
    @include rotate(180deg);
}
