.sponsors-wrapper {
    flex-wrap: wrap;

    section {
        padding: 3px;
        width: 25%;
        height: 200px;

        @media (max-width: $lg) {
            width: 33%;
        }

        @media (max-width: $sm) {
            width: 50%;
        }

        @media (max-width: $xs) {
            width: 100%;
        }

        a {
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            height: 100%;
        }

        img {
            max-width: 90%;
        }
    }
}

.sponsors-shortcut {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: auto;
    p {
        margin-bottom: 5px;
        font-weight: 600;
    }
}

.sponsors-slider {
    display: flex;
    align-items: center;
    justify-content: space-around;

    div {
        max-width: 90px;

        img {
            max-width: 100%;
        }
    }
}

.spons-tabs{
    text-align: center;
     
}
.spons-tabs img {
max-height:173px;
max-width:173px;
margin-top:3px; 

}
@media screen and (max-width: 940px) {
.mob-spons-tab{
    text-align: center;
    }
.mob-spons-tab img{
    max-height:173px;
    max-width:173px;
    padding:2px;

}
    
}




