.tutors-page {
    section {
        padding: 20px 0;
    }

    header {
        margin-bottom: 20px;
    }
}

.tutors-list {
    @extend .directors-list;
    margin-bottom: 20px;

    > li {
        width: 20%;

        @media (max-width: $lg) {
            width: 33%;
        }

        @media (max-width: $sm) {
            width: 100%;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.tutor-name {
    @extend .director-name;
}

.tutor-description-list {
    @extend .director-description-list;
}

.tutors-content {
    @extend .directors-content;

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .tab-content {
        color: $black;

        a {
            color: inherit;
            text-decoration: underline;

            &:hover, &:focus, &:active:focus {
                text-decoration: none;
            }
        }

        dl {
            color: $black;
            margin-bottom: 0;

            dt {
                display: inline-block;
                width: 200px;
                font-weight: 500;
                padding-right: 15px;
            }

            dd {
                display: inline-block;
                max-width: calc(100% - 220px);
                margin-bottom: 25px;
            }
        }
    }
}
