.navbar-default {
    background: none;
    border: none;
    @include transition(all, .2s, ease-in);

    &.white {
        background-color: $white;
    }

    &.yellow {
        background-color: $button;

        &.landing-nav {
            background-color: transparent;
        }
    }

    .navbar-nav {
        > .open {
            .dropdown-menu {
                > li {
                    > a {
                        @media (max-width: $xs) {
                            color: inherit;
                        }
                    }
                }
            }

            > a {
                &, &:hover, &:focus {
                    background: inherit;
                    box-shadow: none;

                    @media (max-width: $sm) {
                        color: inherit;
                    }

                    @media (min-width: $sm) {
                        background: #f5f5f5;
                    }
                }
            }
        }

        > li {
            @media (max-width: $sm) {
                padding: 10px 60px;
            }

            &.active {
                a {
                    border-radius: 10px;
                }
            }

            > a {
                color: $textColorInvert;

                @media (max-width: $sm) {
                    color: inherit;
                    padding: 6px 0;
                    text-transform: uppercase;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background-color: rgba(255,255,255,0.2);
                    }
                }
            }

            &:last-child {
                @media (max-width: $sm) {
                    margin-bottom: 50px;
                }

                a {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }

    .dropdown {
        a {
            position: relative;

            .caret {
                @media (max-width: $sm) {
                    display: none;
                }
            }

            .caret-mobile {
                display: inline-block;
                background-image: url(../images/icons/pointer-dropdown.svg);
                background-repeat: no-repeat;
                background-position: center;
                float: right;
                width: 14px;
                height: 20px;

                @media (min-width: $sm) {
                    display: none;
                }
            }
        }
    }

    .navbar-collapse {
        border: none;
        box-shadow: none;
    }

    .navbar-brand {
        color: inherit;
    }

    .navbar-toggle {
        &:hover {
            background: none;
        }

        .icon-bar {
            background-color: $white;
        }
    }
}

.navbar-fixed-top {
    .navbar-collapse {
        max-height: 100vh;
    }
}

.navbar-brand {
    font-size: 26px;
    font-weight: 600;
}

.navbar-left {
    margin-left: -15px;
}

.navbar-toggle {
    background: none;
    border: none;
    padding: 13px 22px;
    margin-top: 0;
    margin-bottom: 0;

    &:hover, &:focus, &:active:focus {
        background: none;
        background-color: transparent;
        border: none;
    }

    .icon-bar {
        @include standardtransition;
        background-color: $white;
        width: 28px;
        height: 4px;
        border-radius: 2px;

        &:nth-of-type(2) {
            opacity: 0;
        }

        &:nth-of-type(3) {
            @include rotate(-45deg);
        }

        &:nth-of-type(4) {
            @include rotate(45deg);
        }

        & + .icon-bar {
            margin-top: 3px;
            margin-bottom: -7px;
        }
    }

    &.collapsed {
        .icon-bar {
            opacity: 1;
            @include rotate(0);
            width: 36px;

            & + .icon-bar {
                margin-top: 6px;
                margin-bottom: 0;
            }
        }
    }
}

.affix-top {
    @media (min-width: $sm) {
        padding: 24px 0;
    }
}

.affix {
    @media (min-width: $sm) {
        padding: 12px 0;

        &.navbar-default {
            /*background-color: $white;*/
            &.yellow {
                background-color: $button;
            }
        }

        .navbar-nav {
            > li {
                > a {
                    @include transition(all, .2s, ease-in);
                    color: $textColorInvert;
                }
            }
        }
    }
}

.dropdown {
    &.open {

        .dropdown-toggle {
            border-radius: 10px 10px 0 0;
            -webkit-box-shadow: 0 0 12px rgba(0,0,0,.175);
            box-shadow: 0 0 12px rgba(0,0,0,.175);
        }
    }
}

.dropdown-menu {
    margin: 0;
    padding: 0;
    border: none;
    overflow: hidden;
    border-radius: 10px;

    > li {
        > a {
            font-size: 18px;
            color: $textColorInvert;
            padding: 8px 16px;

            @media (max-width: $sm) {
                color: inherit;
            }
        }
    }
}

.navbar-nav {
    > li {
        > .dropdown-menu {
            border-top-right-radius: 10px;
        }
    }
}

//TODO: implement this navigation styling in main page scroll event due to psd

.nav-blue {
    background-color: $navblue;
    color: $white;
}

.nav-lime {
    background-color: $navlime;
    color: $textColorInvert;

    .navbar-toggle .icon-bar {
        background-color: $textColorInvert;
    }

    .dropdown a .caret-mobile {
        background-image: url(../images/icons/pointer-dropdown-dark.svg);
    }
}

.nav-pink {
    background-color: $navpink;
    color: $white;
}

.nav-yellow-text-white {
    background-color: $navyellow;
    color: $white;
}

.nav-yellow {
    @extend .nav-lime;
    background-color: $navyellow;
}

.nav-brown {
    background-color: $navbrown;
    color: $white;
}
