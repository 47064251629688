﻿.contactForm {

    background-color: #53351d;

    .contactWr {
        margin: 50px 20px 35px 20px;
     
                  
    .textUs {

        > p {
       font-weight: 600;
       font-size: 2em;
       padding-bottom: 0px;
        }



         text-align:center;  
         
         h2 {
             margin-bottom: 20px;
         }    

         h5 {
         color: #a69c93;
          }
    }

     .inputForm {

         display: flex;
         justify-content: space-between;
         margin-top: 25px;
       
          .inputExtent {
            width: 48.5%;
            
       }
      
     } 
     .btn-contact {

          width: 100%;
          margin-top: 16px;
          margin-bottom: 45px;
         
      }
     .mobileContact > br {
         color: #53351d;

     }

     .contactList {

         margin-top: 50px;
         margin-bottom:15px;
         
                 
     }
     .contactUs-subheading {
         color: white;
         font-weight: 600;
     }
     
     .list-contact span {
         color: #a69c93;
         
         a {
             color: white;
             margin-left: 5px;
         }
     }
   }


}