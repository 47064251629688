@media (min-width: 1494px) {
    .container {
        width: 1388px;
    }
}

.content {
    padding-top: 120px;

    @media (max-width: $sm) {
        padding-top: 60px;
    }

    .container, .container-narrow {
        position: relative;
    }
}

.container-narrow {
    margin: auto;
    padding: 0 15px;


    @media (min-width: 768px) {
        width: 750px;
    }

    @media (min-width: 992px) {
        width: 970px;
    }

    @media (min-width: 1200px) {
        width: 1145px;
    }
}
