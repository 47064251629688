
@include keyframes(bounce) {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0) rotate(0.02deg);
    }

    40% {
        transform: translateY(-36px) rotate(0.02deg);
    }

    60% {
        transform: translateY(-12px) rotate(0.02deg);
    }
}

@include keyframes(bounce-X) {
    0% {
        transform: translateX(0px) rotate(0.02deg);
    }

    15% {
        transform: translateX(18px) rotate(0.02deg);
    }

    50% {
        transform: translateX(48px) rotate(0.02deg);
    }

    75% {
        transform: translateX(18px) rotate(0.02deg);
    }

    100% {
        transform: translateX(0) rotate(0.02deg);
    }
}

@include keyframes(bounce-02) {
    0% {
        transform: translateY(0px) rotate(0.02deg);
    }

    15% {
        transform: translateY(-18px) rotate(0.02deg);
    }

    50% {
        transform: translateY(-48px) rotate(0.02deg);
    }

    75% {
        transform: translateY(-18px) rotate(0.02deg);
    }

    100% {
        transform: translateY(0) rotate(0.02deg);
    }
}
